export default [
    {
        headerName: 'ID',
        field: 'id',
    },
    {
        headerName: 'LEA',
        field: 'lea',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Update Date',
        field: 'updateDate',
        filter: 'date',
    },
    {
        headerName: 'Grading Period Id',
        field: 'gradingPeriodId',
        filter: false,
    },
    {
        headerName: 'Grading Period Name',
        field: 'gradingPeriodName',
        filter: false,
    },
    {
        headerName: 'Grading Period First Day',
        field: 'gradingPeriodFirstDay',
        filter: false,
    },
    {
        headerName: 'Grading Period Last Day',
        field: 'gradingPeriodLastDay',
        filter: false,
    },
    {
        headerName: 'Number of School Days',
        field: 'numberOfSchoolDays',
        filter: false,
    },
    {
        headerName: 'Term Number',
        field: 'termNumber',
        filter: false,
    },
    {
        headerName: 'Term Type',
        field: 'termType',
        filter: false,
    },
    {
        headerName: 'Session Type',
        field: 'sessionType',
        filter: false,
    },
]
